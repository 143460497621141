import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				I AM CAT SIMULATOR
			</title>
			<meta name={"description"} content={"Embark on a whimsical journey as a playful cat, exploring the world, causing mischief, and living a life full of purr-sonality in *I Am Cat Simulator*."} />
			<meta property={"og:title"} content={"I AM CAT SIMULATOR"} />
			<meta property={"og:description"} content={"Embark on a whimsical journey as a playful cat, exploring the world, causing mischief, and living a life full of purr-sonality in *I Am Cat Simulator*."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});